import React from "react";
import Seo from "../components/seo";
import ProductLayout from "../containers/ProductLayout";
import { Section } from '../GlobalStyles.jsx';
import { BG, BG_DARK } from '@etiquette-ui/colors';
import { graphql, useStaticQuery } from "gatsby";
import Description from "../sections/Products/DigitalMenu/description";
import PriceInfo from "../sections/Products/DigitalMenu/price-info";

import '../globalStyles.css';

const DigitalMenu = () => {
  const dataquery = useStaticQuery(query);
  const datainfo = dataquery.allFile.edges;

  const data = {
    currentPage: 3,
    title: 'Digital Menu',
    heroImage: datainfo[4].node.childrenImageSharp[0].fluid,
    heroAlt: datainfo[4].node.base,
    heroLogo: datainfo[0].node.childrenImageSharp[0].fluid,
    heroAltLogo: datainfo[0].node.base,
    heroMobile: datainfo[2].node.childrenImageSharp[0].fluid,
    heroAltMobile: datainfo[2].node.base,
    banerImg: datainfo[3].node.childrenImageSharp[0].fluid,
    banerAlt: datainfo[3].node.base,
    banerImgMobile: datainfo[1].node.childrenImageSharp[0].fluid,
    banerImgAltMobile: datainfo[1].node.base,
    features: [
      {en:'Join new technologies by presenting your customers with a 100% digital menu',
        es:'Súmate a las nuevas tecnologías presentando a tus clientes un menú 100% digital'},
      {en:'Categorize your dishes and drinks',
        es:'Categoriza tus platillos y bebidas'},
      {en:'Includes photos, descriptions and prices for each food/drink',
        es:'Incluye fotos, descripciones y precios para cada comida/bebida'},
      {en:'Allow your customers to add food/drinks to their order and easily share them with the waiter',
        es:'Permite que tus clientes agreguen alimentos/bebidas a su pedido y que los comparta fácilmente con el mesero'},
      {en:'Ability to switch languages',
        es:'Multi idioma'},
      {en:'Built-in search engine to make it easy to find a food/drink',
        es:'Motor de búsqueda integrado para facilitar la búsqueda'}
    ],
  };

  return (
    <ProductLayout data={data} >
      <Seo title={data.title} />
      <Section color={BG}>
        <Description location={data.title} />
      </Section>
      <Section color={BG_DARK}>
        <PriceInfo />
      </Section>
    </ProductLayout >
  );
};
export default DigitalMenu;

const query = graphql`
query digitalHero {
    allFile(
      filter: {extension: {regex: "/(png)/"}, 
        name: {in: [
          "digitalmenu-hero",
          "digitalmenu-icon",
          "digitalmenu-hero-mobile",
          "digitalmenu-banner",
          "digitalmenu-banner-mobile"
        ]}}
    ) {
      edges {
        node {
          base
          childrenImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }  
`;