import React from 'react'
import Highlight from '../../../components/Highlight';
import { StaticImage } from "gatsby-plugin-image";
import { ThreeColumns, Container } from '../../../GlobalStyles';

import useI18n from '../../../i18n/useI18n';

const PriceInfo = () => {
    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'Include',
        es: 'Agrega'
    }    
    const title = {
        en: 'photos, descriptions, prices and more',
        es: 'fotos, descripciones, precios y más'
    }
    
    return (
        <Container>
            <ThreeColumns>
                <div className='col-1'data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-duration='700'>
                    <StaticImage
                        src="../../../images/digitalmenu-2.png"
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="PriceInfo-2"
                        className="img-container"
                        imgClassName="img-fluid"
                    />
                </div>

                <div className='center'data-sal="slide-up" data-sal-easing="ease-in-circ">
                    <h2><Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}</h2>
                    <p>
                        {translateObject({
                            en: `Show all the categories and subcategories that you have, 
                            tell to your costumers all the important information of each one and
                            make your dishes and drinks more attractive when they see them on your 
                            menu with beauty photographs and clear descriptions.`,
                            es: `Muestra todas las categorías y subcategorías de tus productos. 
                            Enseña la información importantes de tus platillos y bebidas 
                            de una forma más atractiva para tus clientes por medio de fotografías y descripciones claras.`
                        })}
                    </p>
                </div>

                <div className='col-3'data-sal='slide-left' data-sal-easing="ease-in-circ" data-sal-duration='700'>
                    <StaticImage
                        src="../../../images/digitalmenu-3.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="PriceInfo-3"
                        className='img-container'
                        imgClassName='overflowed-img'
                    />
                </div>
            </ThreeColumns>
        </Container>
    )
};

export default PriceInfo;