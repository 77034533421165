import React from 'react'
import Position from '../../../components/Position';
import Highlight from '../../../components/Highlight';
import { Container, Space, Center, Grid } from '../../../GlobalStyles';
import { StaticImage } from "gatsby-plugin-image";

import useI18n from '../../../i18n/useI18n';

const Description = ({ location }) => { 

    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'Technology',
        es: 'Tecnología'
    }    
    const title = {
        en: 'for your costumers',
        es: 'para tus clientes'
    }

    return (
        <Container>
            <Space />
            <Position location={location} />
            <Center>
                <h2 data-sal="slide-up" data-sal-easing="ease-in-circ">
                    <Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}
                </h2>
                <p data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-delay=".2s">
                    {translateObject({
                        en: `A digital menu will help you reduce costs, it will avoid printing new menus every time there are small changes. It will also help to better promote your new dishes by making them highly visible to your customers.`,
                        es: `El menú digital que te ayudará a reducir costos al evitar reimpresiones por cada mínimo cambio, también es muy útil para promover nuevos platillos y que tus clientes los noten como destacados.`
                    })}
                    
                </p>
            </Center>


            <Grid>
                <div className='col-1'data-sal='slide-right' data-sal-easing="ease-in-circ">
                    <h3>
                        {translateObject({
                            en: `Avoid physical contact`,
                            es: `Evita el contacto físico`
                        })}                        
                    </h3>
                    <p>
                        {translateObject({
                            en: `Give your customers confidence and assurance that they
                            have sanitary measures to reduce contact with commonly
                            used surfaces such as printed menus.`,
                            es: `Brinda certeza y seguridad a tu clientes con medidas sanitarias 
                            que reducen el contacto indirecto que se da con los menús impresos.`
                        })}                       
                    </p>
                </div>

                <div className='col-2'data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-duration='300'>
                    <StaticImage
                        src="../../../images/digitalmenu-1.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="description-1"
                        style={{ width: '100%', height: 'fit-content' }}
                    />
                </div>

                <div className='col-3'data-sal='slide-left' data-sal-easing="ease-in-circ">
                    <h3>
                        {translateObject({
                            en: `Keep updated anytime`,
                            es: `Manténlo actualizado siempre`
                        })}                         
                    </h3>
                    <p>
                        {translateObject({
                            en: `Is there a change in any of the dishes? Do you have a new
                            drink? Show them to your customers at the same moment, don’t
                            wait days for your printing providers to give you the service.`,
                            es: `¿Uno de tus platillos cambió?, ¿Tienes una nueva bebida?. Ofrécelas en tu menú 
                            de inmediato, ya no es necesario esperar días por una nueva versión impresa.`
                        })}
                    </p>
                </div>
            </Grid>
        </Container>
    )
};

export default Description;